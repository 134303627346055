import { Cookie } from '../utility'

    const cookieConsent = Cookie.getCookie('cookie-consent')

        !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');

//conditionally set consent
fbq('consent', cookieConsent === 'true' ? 'grant' : 'revoke')

//must delete cookie manually
if(cookieConsent !== 'true'){
    Cookie.deleteCookie('_fbp', { path: '', domain: '.nordiskmusculupati.dk' })
}

fbq('init', '995146614310610');
fbq('track', 'PageView');
    

   

