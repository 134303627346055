import Cookies from 'js-cookie'

export const setCookie = (name, value, options) => {
  Cookies.set(name, value, options)
}

export const getCookie = (name) => {
  const cookie = Cookies.get(name)

  return cookie
}

export const deleteCookie = (name, options) => {
  Cookies.remove(name, options)
}
