//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Cookie } from '../utility'

export default {
  data: function () {
    return {
      cookieConsent: undefined,
      showCookieBanner: false,
      showCookie: false
    }
  },
  mounted: function () {
    const cookieConsent = Cookie.getCookie('cookie-consent')
    this.cookieConsent = cookieConsent

    // set inital view
    if (cookieConsent === undefined) {
      this.showCookieBanner = true
    }
    if (cookieConsent === 'true' || cookieConsent === 'false') {
      this.showCookie = true
    }
  },
  methods: {
    accept: function () {
      Cookie.setCookie('cookie-consent', 'true', { expires: 365 })
      location.reload()
    },
    reject: function () {
      Cookie.setCookie('cookie-consent', 'false', { expires: 365 })
      location.reload()
    },
    closeCookieBanner: function () {
      this.showCookie = true
      this.showCookieBanner = false
    },
    openCookieBanner: function () {
      this.showCookie = false
      this.showCookieBanner = true
    }
  }
}
