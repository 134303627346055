/*
bootstrap 5 menu toggle on mobile doesnt work on single page applications
so when a user presses a link in the menu, they get navigated but the menu stays expanded

a fix:
https://stackoverflow.com/questions/42401606/how-to-hide-collapsible-bootstrap-navbar-on-click

unanswered questions:
https://stackoverflow.com/questions/72197837/vue-single-page-application-bootstrap-5-nav-not-shrinking-on-mobile
*/

const navLinks = document.querySelectorAll('.nav-item:not(.dropdown)')
const menuToggle = document.getElementById('navbarSupportedContent')

const bsCollapse = new bootstrap.Collapse(menuToggle, { toggle: false })

navLinks.forEach(function (l) {
  l.addEventListener('click', function () {
  // avoid flickering on desktop
    if (menuToggle.classList.contains('show')) {
      bsCollapse.toggle()
    }
  })
})
