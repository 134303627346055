export const googleMapUrl = 'https://www.google.com/maps/place/Energihuset,+Energivej+30,+1+sal,+2750+Ballerup/@55.7248843,12.3766,17z/data=!3m1!4b1!4m5!3m4!1s0x46525077893fa609:0x380039718d02dd87!8m2!3d55.7248843!4d12.3766'
export const email = 'kontakt@nordiskmusculupati.dk'
export const address = 'Energivej 30, 1 sal, 2750 Ballerup'
export const phone = '61 31 00 94'

export const openingHours = [
  {
    id: 1,
    day: 'Mandag:',
    hours: '8-16'
  },
  {
    id: 2,
    day: 'Tirsday:',
    hours: '8-16'
  },
  {
    id: 3,
    day: 'Onsdag:',
    hours: '8-16'
  },
  {
    id: 4,
    day: 'Torsdag:',
    hours: '8-16'
  },
  {
    id: 5,
    day: 'Fredag:',
    hours: '8-16'
  },
  {
    id: 6,
    day: 'Lørdag:',
    hours: 'Lukket'
  },
  {
    id: 7,
    day: 'Søndag:',
    hours: 'Lukket'
  }
]
